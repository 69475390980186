var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v("Add / Update")])]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.AdminRouter.VehilceList}})],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"210px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Vehicle Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Number'),
                      'is-required': _vm.isFieldRequired('Vehicle Number'),
                    },staticStyle:{"margin-left":"-10px"},attrs:{"label":"Vehicle Number"}},[_c('template',{slot:"label"},[_c('el-popover',{attrs:{"placement":"top-start","title":"Vehicle number examples","width":"210","trigger":"click"}},[[_c('p',[_vm._v("Ba 01 Pa 1234")]),_c('p',[_vm._v("Pradesh 01 001 Pa 1234")])],_c('el-button',{staticClass:"info-icon",attrs:{"slot":"reference","type":"text","icon":"el-icon-question"},slot:"reference"})],2),_vm._v(" Vehicle Number ")],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("Ba 01 Pa 1234")]),_c('p',[_vm._v("Pradesh 01 001 Pa 1234")])]),_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                          required: true,
                          regex: /^\b([A-Za-z]+)\b \b([A-Za-z])\b ([A-Za-z]{2}) ([0-9]{4})$|^\b([A-Za-z]{2})\b ([0-9]{2}) ([A-Za-z]{2,3}) ([0-9]{4})$|^\b([A-Za-z])+\b ([0-9]{2}) ([0-9]{3}) ([A-Za-z]{2}) ([0-9]{4})$/,
                        }),expression:"{\n                          required: true,\n                          regex: /^\\b([A-Za-z]+)\\b \\b([A-Za-z])\\b ([A-Za-z]{2}) ([0-9]{4})$|^\\b([A-Za-z]{2})\\b ([0-9]{2}) ([A-Za-z]{2,3}) ([0-9]{4})$|^\\b([A-Za-z])+\\b ([0-9]{2}) ([0-9]{3}) ([A-Za-z]{2}) ([0-9]{4})$/,\n                        }"}],attrs:{"placeholder":"Vehicle Number","name":"Vehicle Number"},model:{value:(_vm.vehicle.vehicleNumber),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicleNumber", $$v)},expression:"vehicle.vehicleNumber"}})],1),(_vm.errors.has('Vehicle Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Number")))]):_vm._e()],2)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Registration Date'),
                      'is-required': _vm.isFieldRequired('Registration Date'),
                    },attrs:{"label":"Vehicle Reg Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Date","name":"Registration Date"},on:{"change":_vm.bsDateChange},model:{value:(_vm.vehicle.vehicleRegistrationDateBs),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicleRegistrationDateBs", $$v)},expression:"vehicle.vehicleRegistrationDateBs"}}),_c('br'),(_vm.errors.has('Registration Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Registration Date")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Ownership Type'),
                      'is-required': _vm.isFieldRequired('Ownership Type'),
                    },attrs:{"label":"Ownership Type"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Ownership","name":"Ownership Type"},model:{value:(_vm.vehicle.ownershipType),callback:function ($$v) {_vm.$set(_vm.vehicle, "ownershipType", $$v)},expression:"vehicle.ownershipType"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('br'),(_vm.errors.has('Ownership Type'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Ownership Type")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Type Code'),
                      'is-required': _vm.isFieldRequired('Vehicle Type Code'),
                    },attrs:{"label":"Vehicle Type Code"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Code","name":"Vehicle Type Code"},on:{"change":_vm.onVehicleTypeChange},model:{value:(_vm.vehicle.vehicleTypeCode),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicleTypeCode", $$v)},expression:"vehicle.vehicleTypeCode"}},_vm._l((_vm.vehicleTypeCode),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.code,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Vehicle Type Code'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Type Code")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Type'),
                      'is-required': _vm.isFieldRequired('Vehicle Type'),
                    },attrs:{"label":"Vehicle Type"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Type","name":"Vehicle Type"},model:{value:(_vm.vehicle.vehicleType),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicleType", $$v)},expression:"vehicle.vehicleType"}},_vm._l((_vm.vehicleType),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.type,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Vehicle Type'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Type")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Engine Number'),
                      'is-required': _vm.isFieldRequired('Engine Number'),
                    },attrs:{"label":"Engine Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Engine Number","name":"Engine Number"},model:{value:(_vm.vehicle.engineNumber),callback:function ($$v) {_vm.$set(_vm.vehicle, "engineNumber", $$v)},expression:"vehicle.engineNumber"}}),(_vm.errors.has('Engine Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Engine Number")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Chassis Number'),
                      'is-required': _vm.isFieldRequired('Chassis Number'),
                    },attrs:{"label":"Chassis Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Chassis Number","name":"Chassis Number"},model:{value:(_vm.vehicle.chasisNumber),callback:function ($$v) {_vm.$set(_vm.vehicle, "chasisNumber", $$v)},expression:"vehicle.chasisNumber"}}),(_vm.errors.has('Chassis Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Chassis Number")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Manufacturer'),
                      'is-required': _vm.isFieldRequired('Manufacturer'),
                    },attrs:{"label":"Manufacturer"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Manufacturer","name":"Manufacturer"},model:{value:(_vm.vehicle.manufacturer),callback:function ($$v) {_vm.$set(_vm.vehicle, "manufacturer", $$v)},expression:"vehicle.manufacturer"}}),(_vm.errors.has('Manufacturer'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Manufacturer")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Date of Manufacturer'),
                      'is-required': _vm.isFieldRequired('Date of Manufacturer'),
                    },attrs:{"label":"Date of Manufacturer"}},[_c('el-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Date","value-format":"yyyy-MM-dd","name":"Date of Manufacturer"},model:{value:(_vm.vehicle.dateOfManufacture),callback:function ($$v) {_vm.$set(_vm.vehicle, "dateOfManufacture", $$v)},expression:"vehicle.dateOfManufacture"}}),_c('br'),(_vm.errors.has('Date of Manufacturer'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Date of Manufacturer")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Weight'),
                      'is-required': _vm.isFieldRequired('Weight'),
                    },attrs:{"label":"Weight(GVW)"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Weight","name":"Weight"},model:{value:(_vm.vehicle.weightGvw),callback:function ($$v) {_vm.$set(_vm.vehicle, "weightGvw", $$v)},expression:"vehicle.weightGvw"}}),(_vm.errors.has('Weight'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Weight")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Model'),
                      'is-required': _vm.isFieldRequired('Vehicle Model'),
                    },attrs:{"label":"Vehicle Model"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Vehicle Model","name":"Vehicle Model"},model:{value:(_vm.vehicle.vehicleModel),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicleModel", $$v)},expression:"vehicle.vehicleModel"}}),(_vm.errors.has('Vehicle Model'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Model")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Engine Model'),
                      'is-required': _vm.isFieldRequired('Engine Model'),
                    },attrs:{"label":"Engine Model"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select","name":"Engine Model"},model:{value:(_vm.vehicle.engineModel),callback:function ($$v) {_vm.$set(_vm.vehicle, "engineModel", $$v)},expression:"vehicle.engineModel"}},_vm._l((_vm.engineModel),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Engine Model'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Engine Model")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Seat Capacity'),
                      'is-required': _vm.isFieldRequired('Seat Capacity'),
                    },attrs:{"label":"Seat Capacity"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Seat Capacity","name":"Seat Capacity"},model:{value:(_vm.vehicle.seatCapacity),callback:function ($$v) {_vm.$set(_vm.vehicle, "seatCapacity", $$v)},expression:"vehicle.seatCapacity"}}),(_vm.errors.has('Seat Capacity'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Seat Capacity")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Engine Displacement'),
                      'is-required': _vm.isFieldRequired('Engine Displacement'),
                    },attrs:{"label":"Engine Displacement"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Engine Displacement","name":"Engine Displacement"},model:{value:(_vm.vehicle.engineDisplacement),callback:function ($$v) {_vm.$set(_vm.vehicle, "engineDisplacement", $$v)},expression:"vehicle.engineDisplacement"}}),(_vm.errors.has('Engine Displacement'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Engine Displacement")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Max Power'),
                      'is-required': _vm.isFieldRequired('Max Power'),
                    },attrs:{"label":"Max Power"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Max Power","name":"Max Power"},model:{value:(_vm.vehicle.maxPower),callback:function ($$v) {_vm.$set(_vm.vehicle, "maxPower", $$v)},expression:"vehicle.maxPower"}}),(_vm.errors.has('Max Power'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Max Power")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"5px"}},[_vm._v(" GPS Service ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('GPS Service Provider'),
                      'is-required': _vm.isFieldRequired('GPS Service Provider'),
                    },attrs:{"label":"GPS Service Provider"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select","name":"GPS Service Provider"},on:{"change":_vm.onGpsServiceProviderChange},model:{value:(_vm.vehicle.gpsServiceProvider),callback:function ($$v) {_vm.$set(_vm.vehicle, "gpsServiceProvider", $$v)},expression:"vehicle.gpsServiceProvider"}},_vm._l((_vm.serviceProvider),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.companyName,"value":item.id}})}),1),_c('br'),(_vm.errors.has('GPS Service Provider'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("GPS Service Provider")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('GPS Model'),
                      'is-required': _vm.isFieldRequired('GPS Model'),
                    },attrs:{"label":"GPS Model"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select","name":"GPS Model"},model:{value:(_vm.vehicle.gpsModel),callback:function ($$v) {_vm.$set(_vm.vehicle, "gpsModel", $$v)},expression:"vehicle.gpsModel"}},_vm._l((_vm.devices),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.deviceName,"value":item.id}})}),1),_c('br'),(_vm.errors.has('GPS Model'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("GPS Model")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"GPS Model UniqueId"}},[_c('el-input',{attrs:{"placeholder":"GPS Device Unique Id","name":"Device UniqueId"},model:{value:(_vm.vehicle.tempGpsdeviceUniqueId),callback:function ($$v) {_vm.$set(_vm.vehicle, "tempGpsdeviceUniqueId", $$v)},expression:"vehicle.tempGpsdeviceUniqueId"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('GPS Installation Proof'),
                      'is-required': _vm.isFieldRequired(
                        'GPS Installation Proof'
                      ),
                    },attrs:{"label":"GPS Installation Proof"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.gpsInstallationProof,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"GPS Installation Proof","size":"small","type":"primary","round":""},model:{value:(_vm.gpsInstallationProof),callback:function ($$v) {_vm.gpsInstallationProof=$$v},expression:"gpsInstallationProof"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.gpsInstallationProofName,"dataLink":_vm.vehicle.documents.gpsInstallationProof,"type":"editimage"}}),(
                        !_vm.vehicle.documents.gpsInstallationProof &&
                          !_vm.errors.has('GPS Installation Proof')
                      )?_c('small',{staticClass:"error",staticStyle:{"font-weight":"bold"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]):_vm._e(),(_vm.errors.has('GPS Installation Proof'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("GPS Installation Proof")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"5px"}},[_vm._v(" Route Permits ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Route'),
                      'is-required': _vm.isFieldRequired('Route'),
                    },attrs:{"label":"Route"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select","name":"Route"},model:{value:(_vm.vehicle.routePermits[0].route),callback:function ($$v) {_vm.$set(_vm.vehicle.routePermits[0], "route", $$v)},expression:"vehicle.routePermits[0].route"}},_vm._l((_vm.routes),function(item){return _c('el-option',{key:item.gid,attrs:{"label":item.name,"value":item.gid}})}),1),_c('br'),(_vm.errors.has('Route'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Route")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Route Permit'),
                      'is-required': _vm.isFieldRequired('Route Permit'),
                    },attrs:{"label":"Route Permit"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChangeRoutePermitCertificate,"on-remove":_vm.handleRemoveRoutePermitCertificate,"file-list":_vm.routePermitCertificate,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Route Permit","size":"small","type":"primary","round":""},model:{value:(_vm.routePermitCertificate),callback:function ($$v) {_vm.routePermitCertificate=$$v},expression:"routePermitCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.routePermits[0].routePermitCertificateName,"dataLink":_vm.vehicle.routePermits[0].routePermitCertificate,"type":"editimage"}}),(
                        !_vm.vehicle.routePermits[0].routePermitCertificate &&
                          !_vm.errors.has('Route Permit')
                      )?_c('small',{staticClass:"error",staticStyle:{"margin-top":"-10px","font-weight":"bold"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]):_vm._e(),(_vm.errors.has('Route Permit'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Route Permit")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Issue Date'),
                      'is-required': _vm.isFieldRequired('Issue Date'),
                    },attrs:{"label":"Issue Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Issue Date","name":"Issue Date"},on:{"change":_vm.bsDateChange1},model:{value:(_vm.vehicle.routePermits[0].issuedDateBs),callback:function ($$v) {_vm.$set(_vm.vehicle.routePermits[0], "issuedDateBs", $$v)},expression:"vehicle.routePermits[0].issuedDateBs"}}),_c('br'),(_vm.errors.has('Issue Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Issue Date")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Expiry Date'),
                      'is-required': _vm.isFieldRequired('Expiry Date'),
                    },attrs:{"label":"Expiry Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Expiry Date","name":"Expiry Date"},on:{"change":_vm.bsDateChange2},model:{value:(_vm.vehicle.routePermits[0].expiryDateBs),callback:function ($$v) {_vm.$set(_vm.vehicle.routePermits[0], "expiryDateBs", $$v)},expression:"vehicle.routePermits[0].expiryDateBs"}}),_c('br'),(_vm.errors.has('Expiry Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Expiry Date")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12,"hidden":""}},[_c('el-form-item',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle.routePermits[0].isActive),expression:"vehicle.routePermits[0].isActive"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.vehicle.routePermits[0].isActive)?_vm._i(_vm.vehicle.routePermits[0].isActive,null)>-1:(_vm.vehicle.routePermits[0].isActive)},on:{"change":function($event){var $$a=_vm.vehicle.routePermits[0].isActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.vehicle.routePermits[0], "isActive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.vehicle.routePermits[0], "isActive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.vehicle.routePermits[0], "isActive", $$c)}}}}),_vm._v(" Is Active ")])],1)],1)],1),_c('h4',{staticStyle:{"margin-left":"40px"}},[_vm._v(" Supporting Documents ("),_c('small',{staticStyle:{"color":"#E63946"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]),_vm._v(") ")]),_c('el-row',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Tax Payment'),
                      'is-required': _vm.isFieldRequired('Vehicle Tax Payment'),
                    },attrs:{"label":"Vehicle Tax Payment"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange6,"on-remove":_vm.handleRemove6,"file-list":_vm.vehicleTaxPaymentReceipt,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Vehicle Tax Payment","size":"small","type":"primary","round":""},model:{value:(_vm.vehicleTaxPaymentReceipt),callback:function ($$v) {_vm.vehicleTaxPaymentReceipt=$$v},expression:"vehicleTaxPaymentReceipt"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.vehicleTaxPaymentReceiptName,"dataLink":_vm.vehicle.documents.vehicleTaxPaymentReceipt,"type":"editimage"}}),(_vm.errors.has('Vehicle Tax Payment'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Tax Payment")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Insurance'),
                      'is-required': _vm.isFieldRequired('Vehicle Insurance'),
                    },attrs:{"label":"Vehicle Insurance"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange1,"on-remove":_vm.handleRemove1,"file-list":_vm.vehicleInsuranceCertificate,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Vehicle Insurance","size":"small","type":"primary","round":""},model:{value:(_vm.vehicleInsuranceCertificate),callback:function ($$v) {_vm.vehicleInsuranceCertificate=$$v},expression:"vehicleInsuranceCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.vehicleInsuranceCertificateName,"dataLink":_vm.vehicle.documents.vehicleInsuranceCertificate,"type":"editimage"}}),(_vm.errors.has('Vehicle Insurance'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Insurance")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle Testing'),
                      'is-required': _vm.isFieldRequired('Vehicle Testing'),
                    },attrs:{"label":"Vehicle Testing"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange2,"on-remove":_vm.handleRemove2,"file-list":_vm.vehicleTestingCertificate,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Vehicle Testing","size":"small","type":"primary","round":""},model:{value:(_vm.vehicleTestingCertificate),callback:function ($$v) {_vm.vehicleTestingCertificate=$$v},expression:"vehicleTestingCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.vehicleTestingCertificateName,"dataLink":_vm.vehicle.documents.vehicleTestingCertificate,"type":"editimage"}}),(_vm.errors.has('Vehicle Testing'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Testing")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Bluebook 1'),
                      'is-required': _vm.isFieldRequired('Bluebook 1'),
                    },attrs:{"label":"Bluebook page 1"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange3,"on-remove":_vm.handleRemove3,"file-list":_vm.blueBook1,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Bluebook 1","size":"small","type":"primary","round":""},model:{value:(_vm.blueBook1),callback:function ($$v) {_vm.blueBook1=$$v},expression:"blueBook1"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.blueBook1Name,"dataLink":_vm.vehicle.documents.blueBook1,"type":"editimage"}}),(_vm.errors.has('Bluebook 1'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Bluebook 1")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Bluebook 2'),
                      'is-required': _vm.isFieldRequired('Bluebook 2'),
                    },attrs:{"label":"Bluebook page 2"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange4,"on-remove":_vm.handleRemove4,"file-list":_vm.blueBook2,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Bluebook 2","size":"small","type":"primary","round":""},model:{value:(_vm.blueBook2),callback:function ($$v) {_vm.blueBook2=$$v},expression:"blueBook2"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.blueBook2Name,"dataLink":_vm.vehicle.documents.blueBook2,"type":"editimage"}}),(_vm.errors.has('Bluebook 2'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Bluebook 2")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors(
                        'Proof of association with TC'
                      ),
                      'is-required': _vm.isFieldRequired(
                        'Proof of association with TC'
                      ),
                    },attrs:{"label":"Proof of relation with TC"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange7,"on-remove":_vm.handleRemove7,"file-list":_vm.transportCompanyCertificate,"multiple":false,"accept":"image/*"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],attrs:{"name":"Proof of association with TC","size":"small","type":"primary","round":""},model:{value:(_vm.transportCompanyCertificate),callback:function ($$v) {_vm.transportCompanyCertificate=$$v},expression:"transportCompanyCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('detail-tag',{attrs:{"data":_vm.vehicle.documents.transportCompanyCertificateName,"dataLink":_vm.vehicle.documents.transportCompanyCertificate,"type":"editimage"}}),(_vm.errors.has('Proof of association with TC'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Proof of association with TC")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }