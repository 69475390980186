























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import {
  GPSServiceProviderModule as gpsServiceStore,
  VehicleModule as vehicleStore,
  MetaModule as metaStore,
} from "@/store/modules";
import ownershipStore from "@/store/modules/ownership";
import { GpsDevice } from "@/store/models/registration/registration";
import { VehicleType } from "@/store/models/meta";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";
import { BSToAD } from "bikram-sambat-js";

@Component({
  components: {
    Input,
    Button,
    Form,
    DetailTag,
    NepaliDatePicker,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  devices: GpsDevice[] = [];
  vehicleType: VehicleType[] = [];
  gpsInstallationProof: File[] = [] as File[];
  routePermitCertificate: File[] = [] as File[];
  vehicleTaxPaymentReceipt: File[] = [] as File[];
  vehicleInsuranceCertificate: File[] = [] as File[];
  vehicleTestingCertificate: File[] = [] as File[];
  transportCompanyCertificate: File[] = [] as File[];
  blueBook1: File[] = [] as File[];
  blueBook2: File[] = [] as File[];

  data() {
    return {
      options: [
        {
          value: "Commercial",
          label: "Commercial",
        },
        {
          value: "Non-Commercial",
          label: "Non-Commercial",
        },
      ],
      value: "",
    };
  }

  get isFileRequired() {
    if (this.$route.query.id) {
      return false;
    }
    return true;
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get vehicle() {
    return vehicleStore.vehicle;
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.gpsInstallationProof = [file.raw];
      vm.vehicle.documents.gpsInstallationProof = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.gpsInstallationProof = [];
      this.vehicle.documents.gpsInstallationProof = null;
    }
  }
  handleRemove(file: any, fileList: any) {
    let vm = this;
    this.gpsInstallationProof = [];
    vm.vehicle.documents.gpsInstallationProof = null;
  }

  handleChange1(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.vehicleInsuranceCertificate = [file.raw];
      vm.vehicle.documents.vehicleInsuranceCertificate = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.vehicleInsuranceCertificate = [];
      vm.vehicle.documents.vehicleInsuranceCertificate = null;
    }
  }

  handleRemove1(file: any, fileList: any) {
    let vm = this;
    this.vehicleInsuranceCertificate = [];
    vm.vehicle.documents.vehicleInsuranceCertificate = null;
  }

  handleChange2(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.vehicleTestingCertificate = [file.raw];
      vm.vehicle.documents.vehicleTestingCertificate = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.vehicleTestingCertificate = [];
      vm.vehicle.documents.vehicleTestingCertificate = null;
    }
  }

  handleRemove2(file: any, fileList: any) {
    let vm = this;
    this.vehicleTestingCertificate = [];
    vm.vehicle.documents.vehicleTestingCertificate = null;
  }

  handleChange3(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.blueBook1 = [file.raw];
      vm.vehicle.documents.blueBook1 = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.blueBook1 = [];
      vm.vehicle.documents.blueBook1 = null;
    }
  }
  handleRemove3(file: any, fileList: any) {
    let vm = this;
    this.blueBook1 = [];
    vm.vehicle.documents.blueBook1 = null;
  }

  handleChange4(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.blueBook2 = [file.raw];
      vm.vehicle.documents.blueBook2 = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.blueBook2 = [];
      vm.vehicle.documents.blueBook2 = null;
    }
  }
  handleRemove4(file: any, fileList: any) {
    let vm = this;
    this.blueBook2 = [];
    vm.vehicle.documents.blueBook2 = null;
  }

  handleChangeRoutePermitCertificate(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.routePermitCertificate = [file.raw];
      vm.vehicle.routePermits[0].routePermitCertificate = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.routePermitCertificate = [];
      vm.vehicle.routePermits[0].routePermitCertificate = null;
    }
  }
  handleRemoveRoutePermitCertificate(file: any, fileList: any) {
    let vm = this;
    this.routePermitCertificate = [];
    vm.vehicle.routePermits[0].routePermitCertificate = null;
  }

  handleChange6(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.vehicleTaxPaymentReceipt = [file.raw];
      vm.vehicle.documents.vehicleTaxPaymentReceipt = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.vehicleTaxPaymentReceipt = [];
      vm.vehicle.documents.vehicleTaxPaymentReceipt = null;
    }
  }
  handleRemove6(file: any, fileList: any) {
    let vm = this;
    this.vehicleTaxPaymentReceipt = [];
    vm.vehicle.documents.vehicleTaxPaymentReceipt = null;
  }

  handleChange7(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.transportCompanyCertificate = [file.raw];
      vm.vehicle.documents.transportCompanyCertificate = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.transportCompanyCertificate = [];
      vm.vehicle.documents.transportCompanyCertificate = null;
    }
  }
  handleRemove7(file: any, fileList: any) {
    let vm = this;
    this.transportCompanyCertificate = [];
    vm.vehicle.documents.transportCompanyCertificate = null;
  }

  addRoutePermit() {
    this.vehicle.routePermits.push({
      id: 0,
      route: 0,
      routePermitCertificate: "",
      isActive: true,
      issuedDate: "",
      issuedDateBs: "",
      expiryDate: "",
      expiryDateBs: "",
    });
  }

  deleteRoutePermit(counter: any) {
    this.vehicle.routePermits.splice(counter, 1);
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    let a = this.vehicle.routePermits.length;

    if (this.vehicle.id) {
      // Remove File Fields without file
      for (let index = 0; index < a; index++) {
        this.removeFiles(
          this.vehicle.routePermits[index],
          "routePermitCertificate"
        );
      }

      this.removeFiles(this.vehicle.documents, "vehicleTestingCertificate");
      this.removeFiles(this.vehicle.documents, "vehicleInsuranceCertificate");
      this.removeFiles(this.vehicle.documents, "vehicleTaxPaymentReceipt");
      this.removeFiles(this.vehicle.documents, "blueBook1");
      this.removeFiles(this.vehicle.documents, "blueBook2");
      this.removeFiles(this.vehicle.documents, "citizenId");
      this.removeFiles(this.vehicle.documents, "transportCompanyCertificate");
      this.removeFiles(this.vehicle.documents, "gpsInstallationProof");
      this.removeFiles(this.vehicle.documents, "citizenshipPassport");
    }
    await vehicleStore.createVehicle();
    this.$snotify.success("Vehicle Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.VehilceList);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.VehilceList);
  }

  async created() {
    vehicleStore.resetField();
    let vm = this;
    const id = vm.$route.query["id"];
    await gpsServiceStore.loadActiveGpsServiceProvider();
    await metaStore.loadRoutesDD();
    await metaStore.loadVehicleTypeCodeDD();
    await metaStore.loadEngineModel();
    if (id) {
      vm.isCreate = false;

      await vehicleStore.getVehicleById(id);
    } else {
      vehicleStore.resetField();
    }

    if (this.vehicle.gpsServiceProvider) {
      await gpsServiceStore.loadGpsDevice(this.vehicle.gpsServiceProvider);
      this.devices = gpsServiceStore.gpsDeviceList;
    }
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.vehicle.vehicleRegistrationDate = bsAD;
      this.vehicle.vehicleRegistrationDateBs = e;
    }
  }
  bsDateChange1(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.vehicle.routePermits[0].issuedDate = bsAD;
      this.vehicle.routePermits[0].issuedDateBs = e;
    }
  }
  bsDateChange2(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.vehicle.routePermits[0].expiryDate = bsAD;
      this.vehicle.routePermits[0].expiryDateBs = e;
    }
  }

  removeFiles(property: any, fieldName: any) {
    let field = property[fieldName];
    if (field === undefined || field === null || typeof field === "string") {
      delete property[fieldName];
    }
  }

  async onGpsServiceProviderChange(e: any) {
    await gpsServiceStore.loadGpsDevice(e);
    this.devices = gpsServiceStore.gpsDeviceList;
  }

  async onVehicleTypeChange(e: any) {
    this.vehicleType = await metaStore.loadVehicleTypeDD(e);
  }

  get serviceProvider() {
    let a = gpsServiceStore.gpsServiceProviderList;
    return gpsServiceStore.gpsServiceProviderList;
  }

  get routes() {
    return metaStore.routesList;
  }

  get engineModel() {
    return metaStore.engineModelList;
  }

  get vehicleTypeCode() {
    return metaStore.vehicleTypeCodeList;
  }

  mounted() {}
  updated() {}
}
